<template>
  <div style="font-family: PingFangSC-Regular;">
    <van-tabs v-model="activeName" color="#4581f8" title-active-color="#4581f8" line-height="2px">
      <van-tab name="0" title="问题详情">
        <div :class="{'isOld40': isOld == 1}">
          <van-field v-if="dataForm.subareaId" name="位置" label="位置" readonly v-model="dataForm.fullName"/>
          <van-field name="问题类别" label="问题类别" readonly v-model="dataForm.typeStr"/>
          <van-cell title="问题描述" :border="false"/>
          <van-field type="textarea" readonly v-model="dataForm.des" placeholder="无问题描述" autosize maxlength="100"
                     show-word-limit></van-field>
          <van-cell title="图片/视频" class="fieldPicture" :border="false"/>
          <van-field v-if="fileList.length > 0">
            <template #input>
              <upload-file
                  :uploadPath="uploadPath"
                  :fileList="fileList"
                  :delBtnShow="false"
                  :uploadBtnShow="false"
              />
            </template>
          </van-field>
          <van-cell v-else title="无图片/视频" :border="false"></van-cell>
        </div>
      </van-tab>
      <van-tab name="1" title="处理详情">
        <div :class="{'isOld40': isOld == 1}">
          <van-field name="处理人" label="处理人" readonly v-model="dataForm.solveUserStr"/>
          <van-field name="联系电话" label="联系电话" readonly v-model="dataForm.solveUserMobile"/>
          <van-field name="处理时间" label="处理时间" readonly v-model="dataForm.solveTime"/>
          <van-cell title="处理结果" class="deal" :border="false"/>
          <van-field
              type="textarea"
              readonly
              v-model="dataForm.solveDes"
              placeholder="无处理过程"
              autosize maxlength="100"
              show-word-limit>
          </van-field>
          <van-cell title="图片/视频" class="fieldPicture" :border="false"/>
          <van-field v-if="solveFileList.length > 0">
            <template #input>
              <upload-file
                  :uploadPath="uploadPath"
                  :fileList="solveFileList"
                  :delBtnShow="false"
                  :uploadBtnShow="false"
              />
            </template>
          </van-field>
          <van-cell v-else title="无图片/视频" :border="false"></van-cell>
        </div>
      </van-tab>
    </van-tabs>
  </div>

</template>

<script>
import uploadFile from '@/components/upload/uploadFile'
import {getImageStream} from '@/utils/index'

export default {
  data() {
    return {
      activeName: '',
      id: '',
      dataForm: {
        subareaId: '',
        fullName: '',
        typeStr: '',
        solveUserStr: '',
        solveUserMobile: '',
        solveTime: '',
        solveDes: '',
      },
      fileList: [],
      solveFileList: [],
      uploadPath: 'files/problem/temporary',
      isOld: sessionStorage.getItem('isOld')
    }
  },
  components: {
    uploadFile
  },
  created() {
    this.id = this.$route.query.id
    if (this.$route.query.domain && this.$route.query.domain != 'null'){
      this.$http.changeUrl(this.$route.query.domain, true)
      this.$httpApp.changeUrl(this.$route.query.domain, true)
      this.$httpCustApp.changeUrl(this.$route.query.domain, true)
    }
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$httpCustApp({
        url: this.$httpCustApp.adornUrl('/wxapp/problem/info/zzzg'),
        method: 'GET',
        params: this.$httpCustApp.adornParams({
          id: this.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataForm.subareaId = data.entityVO.subarea
          this.dataForm.fullName = data.entityVO.houseFullName
          this.dataForm.typeStr = data.entityVO.typeStr
          if (data.entityVO.problemFiles) {
            this.fileList = data.entityVO.problemFiles.map(item => {
              return {
                ...item,
                fileType: item.type,
                relativePath: item.filePath
              }
            })
          }
          console.log('图片/视频', this.fileList);
          this.dataForm.des = data.entityVO.content
          // 处理详情
          this.dataForm.solveUserStr = data.entityVO.solveUserName
          this.dataForm.solveUserMobile = data.entityVO.solveUserPhoneNumber
          this.dataForm.solveTime = data.entityVO.solveTime
          this.dataForm.solveDes = data.entityVO.solveDes
          if (data.entityVO.problemSolveFiles) {
            this.solveFileList = data.entityVO.problemSolveFiles.map(item => {
              return {
                ...item,
                fileType: item.type,
                relativePath: item.filePath
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.isOld40 >>> .van-cell {
  font-size: 40px !important;
}

.isOld40 >>> .van-field__control {
  font-size: 40px !important;
}

.van-cell >>> .van-field__label {
  width: auto;
  margin-right: 32px;
}

.van-cell >>> .van-field__control {
  font-size: 34px;
}

.van-cell >>> .van-field__word-limit {
  margin-bottom: 12px;
}

.van-picker-column__wrapper >>> .van-picker-column__item--selected {
  font-size: 42px;
}

.van-tabs >>> .van-tab__text--ellipsis {
  font-size: 32px;
}
</style>
<style lang="scss" scoped>
.disable {
  pointer-events: none;
}

.fieldPicture {
  padding-bottom: 0;
}

.repair-detail {
  //   height: 254px;
  background: #FFFFFF;
  margin-top: 16px;
  padding-top: 24px;

  .top {
    height: 84px;
    background: #FFFFFF;
    font-size: 30px;
    font-weight: 400;
    color: #666666;
    padding: 0 30px;
  }

  .des {
    height: 96px;
    font-size: 34px;
    font-weight: 400;
    color: #333333;
  }

  ::v-deep .van-field__word-limit {
    color: #999999;
  }

  ::v-deep .van-cell {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.title {
  width: 100%;
  height: 96px;
  background: #FFFFFF;
  padding: 0 30px;
  font-size: 32px;
  font-weight: 600;
  color: #333333;
  line-height: 96px;
}

.deal {
  padding-bottom: 0;
}

.evaluate {
  padding: 0 30px;
  background: #FFFFFF;
  margin-top: 16px;

  .service-evaluate {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EDEDED;

    .left {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
    }
  }

  .evaluate-check {
    width: 100%;
    // height: 192px;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;

    .check-item {
      // width: 198px;
      height: 60px;
      background: rgba(78, 124, 245, 0.12);
      border-radius: 4px;
      font-size: 30px;
      font-weight: 400;
      color: #4581F8;
      line-height: 60px;
      text-align: center;
      padding: 0 24px;
      margin-right: 24px;
      margin-bottom: 12px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .checked {
      background: #4581F8;
      color: #FFFFFF;
    }
  }

  ::v-deep .van-cell {
    // padding: 0;
    padding-left: 0;
    padding-right: 0;
  }

  ::v-deep .van-cell--borderless {
    padding-bottom: 0;
    padding-top: 24px;
  }
}

.sign-box {
  width: 100%;
  height: 314px;
  background: #FFFFFF;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sign-img {
    width: 690px;
    height: 200px;
    background: #F5F5F5;
    border-radius: 8px;
  }
}

.evaluate-btn {
  padding: 96px 30px 48px;

  .btn {
    width: 100%;
    height: 88px;
    background: #4581F8;
    border-radius: 8px;
    font-size: 32px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 88px;
    text-align: center;
  }
}

.signature {
  width: 100%;

  .sign-bottom {
    display: flex;
    justify-content: space-between;

    .sign-btn {
      width: 200px;
    }

    .confirm {
      font-weight: bold;
      color: #4581F8;
    }
  }

}
</style>
